/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { Button, Container, Header, Icon, Label, Popup,Checkbox, Search, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import ReactTable from 'react-table';
import _ from 'lodash';

import { isStoreError, isStoreLoading } from '@amzn/base-ui/dist/models/BaseStore';
import ErrorBox from '@amzn/base-ui/dist/parts/helpers/ErrorBox';
import { createLink } from '@amzn/base-ui/dist/helpers/routing';
import BasicProgressPlaceholder from '@amzn/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import Stores from '@amzn/base-ui/dist/models/Stores';
import { gotoFn } from '@amzn/base-ui/dist/helpers/routing';

import ProjectConfigure from './ProjectConfigure';

class ProjectsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    runInAction(() => {
      this.stores = new Stores([this.props.projectsStore]);
    });
    
    this.state ={
      projectList :[]
    }
  }
  

  getProjectsStore() {
    const store = this.props.projectsStore;
   // store.load();
    return store;
  }
  
  getUserDisplayName() {
    return this.props.userDisplayName;
  }
  
  getProjects() {
    const user = this.getUser();
    const isAdmin = user?.isAdmin;
    const uid =user?.uid;
    const store = this.getProjectsStore();
    
    let projectsData =  store.list;
     if(projectsData){
    
     projectsData= _.map(projectsData, (pjt) =>{
       
       const isPjtAdmin = _.find(pjt?.projectAdmins, (data)=>{
         
          if(uid == data || isAdmin){
            return true;
          }
        });
        const isPjtResearcher = _.find(pjt?.projectResearchers, (data)=>{
          
          if(uid == data || isAdmin){
           return true;
          }
        });
        const isPjtViewer = _.find(pjt?.projectViewers, (data)=>{
          
          if(uid == data || isAdmin){
            return true;
          }
        });
        
        if(isPjtAdmin || isPjtResearcher || isPjtViewer){
          return pjt;
        }
    });
     projectsData =_.without(projectsData, undefined);
    } 
    return projectsData;
  }
  
  getUserStore() {
    const userStore = this.props.userStore;
    //userStore.load();
    return userStore;
  }
  
  getUser() {
    const userStore =this.getUserStore();
    return userStore.user;
  }

  handleDashboard = (projectID) => {
    const goto = gotoFn(this);
    goto(`/projects/dashboard/${projectID}`);
  };
  
  componentDidMount(){
    let projectsData = this.getProjects();
    
    this.setState({projectList:projectsData});
  }

  renderMain() {
    const user = this.getUser();
    //console.log("userOnList", user);
    const isAdmin = user?.isAdmin;
    const uid =user?.uid;
    
    const usersStore = this.props.usersStore;
    //console.log("usersStore",usersStore);
    
    let projectsData = this.state.projectList;//this.getProjects();
    
   // this.setState({projectList:projectsData});
    //console.log(projectsData);
      
    const pageSize = projectsData.length;
    const pagination = projectsData.length > pageSize;
    //const pageSize = 10;
    //const pagination = projectsData.length > 10;
    return (
      <div>
        {projectsData.length ? <ReactTable
          data={this.state.projectList}
          defaultSorted={[{ id: 'id', desc: false }]}
          showPagination={pagination}
          defaultPageSize={pageSize}
          className="-striped -highlight"
          filterable
          defaultFilterMethod={(filter, row) => {
            /* Previous Implementation */
            // const columnValue = String(row[filter.id]).toLowerCase();
            // const filterValue = filter.value.toLowerCase();
            // return columnValue.indexOf(filterValue) >= 0;
            
            const rawColumnValue = row[filter.id];
            const filterValue = filter.value.toLowerCase();
            
            /* Handle Project Owners, Project Researchers, and Project Viewers columns, which contain arrays of User objects. */
            if (Array.isArray(rawColumnValue)) {
              const userDisplayName = this.getUserDisplayName();
              /* Create a new constant with a new name for clarity's sake. */
              const columnUserArray = rawColumnValue.map((value) => 
                value = userDisplayName.getDisplayName({ uid: value }).toLowerCase()
              );
            
              /* Interpret filterValue as a comma-separated list of filters, allowing users to search for multiple strings. */
              /* The first replace() function replaces all instances of two or more whitespace characters with a single space. */
              /* The second replace() function removes trailing whitespace. */
              /* This split() function splits on commas preceeded or succeeded by 0 or more whitespace characters, and returns an array of filterValues. */
              /* filter(item => item) removes empty strings from the array. */
              /* Ex: "Hunter Irving , Neil     Sedlak, " becomes ["Hunter Irving", "Neil Sedlak"]. */
              const filterStringArray = filterValue.replace(/\s\s+/g, ' ').replace(/\s$/, '').split(/\s*,\s*/).filter(item => item);
              
              /* Iterate over filterStringArray, evaluating whether each element is present in columnUserArray. */
              for (var i = 0; i < filterStringArray.length; i++) {
                let found = false;
            	  for (var j = 0; j < columnUserArray.length; j++) {
            	    if (columnUserArray[j].includes(filterStringArray[i])) {
            	      found = true;
            	      break;
            	    }
            	  }
            	  //If a single element of filterStringArray is missing, return false.
            	  if (found == false) {
            	    return false;
            	  }
              }
              //after iterating through all of filterStringArray and finding each in columnUserArray...
              return true;
            }
            
            /* Handle Project Name and Description columns, which contain Strings. */
            const columnValueString = String(rawColumnValue).toLowerCase();
            return columnValueString.indexOf(filterValue) >= 0;
          }}
          sortable={[
              'id'
          ]}
          columns={[
            // {
            //   Header: 'Project Name',
            //   accessor: 'id',
            //   Cell: observer(row => {
            //   const project = row.original;
            //     const projectId = project.id;
            //     return (
            //     <a href="#" onClick={()=>{this.handleDashboard(projectId)}}>{projectId}</a>
            //     );
            //   }),
            // },
            // {
            //   Header: 'Index Id',
            //   accessor: 'indexId',
            // },
            // {
            //   Header: 'Description',
            //   accessor: 'description',
            //   Cell: observer(cell => {
            //   const descCell = { ...cell.original };
            //     return descCell.description;
            //   }),
            // },
            {
              Header: 'Project Details',
              accessor: 'viewDetail',
              filterable: false,
              disableSortBy: true,
              Cell: observer(cell => {
                const project = { ...cell.original };
                const isAdminUser = _.find(project.projectAdmins, (data)=>{
                  if(uid == data || isAdmin){
                    return true;
                  }
                });
                
                return (
                 isAdminUser && (<div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <span>
                      <Popup
                        content="View Project Detail"
                        trigger={
                          <ProjectConfigure
                            project={project}
                            userStore={this.props.userStore}
                            usersStore={this.props.usersStore}
                            projectsStore={this.props.projectsStore}
                            awsAccountsStore={this.props.awsAccountsStore}
                          />
                        }
                      />
                    </span>
                  </div>)
                );
              }),
            },
            {
              Header: 'Project Dashboard',
              accessor: '',
              filterable: false,
              disableSortBy: true,
              Cell: observer(cell => {
                const project = { ...cell.original };
                const isAdminUser = _.find(project.projectAdmins, (data)=>{
                  if(uid == data || isAdmin){
                    return true;
                  }
                });
                
                return (
                 isAdminUser && (<div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <Button size="mini" compact color="blue" onClick={()=>{this.handleDashboard(project.id)}}>
                    Dashboard
                    </Button>
                  </div>)
                );
              }),
            },
            {
              Header: 'Project Name',
              accessor: 'id',
            },
            {
              Header: 'Project Owners',
              accessor: 'projectAdmins',
              style: { whiteSpace: 'unset' },
              Cell: observer(row => {
                const project = row.original;
                const projectAdminUsers = project.projectAdminUsers;
                 return _.map(projectAdminUsers, (u) =>{
                  if(/\s/.test(u.firstName)) {
                     return u.firstName;
                   }
                   else {
                     return u.firstName + " " + u.lastName;
                   }
                  //return  u.firstName;
                }).join(', ');
              }),
            },
            {
              Header: 'Project Researchers',
              accessor: 'projectResearchers',
              style: { whiteSpace: 'unset' },
              Cell: observer(row => {
                const project = row.original;
                const projectResearchUsers = project.projectResearchUsers;
                return _.map(projectResearchUsers, (u) =>{
                  if(/\s/.test(u.firstName)) {
                     return u.firstName;
                   }
                   else {
                     return u.firstName + " " + u.lastName;
                   }
                  //return  u.firstName;
                }).join(', ');
              }),
            },
            {
              Header: 'Project Viewers',
              accessor: 'projectViewers',
              style: { whiteSpace: 'unset' },
              Cell: observer(row => {
                const project = row.original;
                const projectViwerUsers = project.projectViewerUsers;
                 return _.map(projectViwerUsers, (u) =>{
                   if(/\s/.test(u.firstName)) {
                     return u.firstName;
                   }
                   else {
                     return u.firstName + " " + u.lastName;
                   }
                  //return  u.firstName;
                }).join(', ');
              }),
            },
            {
              Header: 'Project Archived',
              accessor: 'isArchived',
              //filterable: false,
              Cell: observer(cell => {
              const archivedCell = { ...cell.original };
                return (
                <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <Checkbox disabled checked={archivedCell.isArchived}/>
                </div>
                );
              }),
              filterMethod: (filter, row) => {
                if (filter.value === "Show All") {
                  return true;
                }
                else if (filter.value === "Archived") {
                  if (row.isArchived) {
                    return true;
                  }
                }
                else if (filter.value === "Not Archived") {
                  if (! row.isArchived) {
                    return true;
                  }
                }
                else {
                  return false;
                }
              },
              Filter: ({ filter, onChange }) =>
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}>
                  <option value="Show All">Show All</option>
                  <option value="Archived">Archived</option>
                  <option value="Not Archived">Not Archived</option>
                </select>
            },
            {
              Header: 'Sensitive Data',
              accessor: 'hasSensitiveData',
              //filterable: false,
              Cell: observer(cell => {
              const sensitiveCell = { ...cell.original };
                return (
                <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <Checkbox disabled checked={sensitiveCell.hasSensitiveData} />
                </div>
                );
              }),
              filterMethod: (filter, row) => {
                if (filter.value === "Show All") {
                  return true;
                }
                else if (filter.value === "Contains Sensitive Data") {
                  if (row.hasSensitiveData) {
                    return true;
                  }
                }
                else if (filter.value === "Does Not Contain Sensitive Data") {
                  if (! row.hasSensitiveData) {
                    return true;
                  }
                }
                else {
                  return false;
                }
              },
              Filter: ({ filter, onChange }) =>
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}>
                  <option value="Show All">Show All</option>
                  <option value="Contains Sensitive Data">Contains Sensitive Data</option>
                  <option value="Does Not Contain Sensitive Data">Does Not Contain Sensitive Data</option>
                </select>
            },
            {
              Header: 'Compliance Categories',
              accessor: 'complianceCategories',
              //filterable: false,
              Cell: observer(row => {
                const project = row.original;
                const projCompArray = project.complianceCategories;
                const projComp = projCompArray.join(', ');
                return projComp;
              }),
              filterMethod: (filter, row) => {
                /*const compCat = row.complianceCategories.join(', ');
                if (compCat.toLowerCase().includes(filter.value.toLowerCase())) {
                  return true;
                }
                else {
                  return false;
                }*/
                const rawColumnValue = row.complianceCategories; //an array
                const filterValue = filter.value.toLowerCase();
                /* Create a new constant with a new name for clarity's sake. */
                const columnComplianceCategoriesArray = rawColumnValue.map((value) => 
                  value = value.toLowerCase()
                );
              
                const filterStringArray = filterValue.replace(/\s\s+/g, ' ').replace(/\s$/, '').split(/\s*,\s*/).filter(item => item);
                
                /* Iterate over filterStringArray, evaluating whether each element is present in columnUserArray. */
                for (var i = 0; i < filterStringArray.length; i++) {
                  let found = false;
              	  for (var j = 0; j < columnComplianceCategoriesArray.length; j++) {
              	    if (columnComplianceCategoriesArray[j].includes(filterStringArray[i])) {
              	      found = true;
              	      break;
              	    }
              	  }
              	  //If a single element of filterStringArray is missing, return false.
              	  if (found == false) {
              	    return false;
              	  }
                }
                //after iterating through all of filterStringArray and finding each in columnUserArray...
                return true;
              }
            },
            {
              Header: 'Proposal ID',
              accessor: 'proposalID',
              Cell: observer(row => {
                const project = row.original;
                const projectProposalID = project.proposalID;
                return projectProposalID;
              }),
            },
            {
              Header: 'Fund Number',
              accessor: 'fundNumber',
              Cell: observer(row => {
                const project = row.original;
                const projectFundNumber = project.fundNumber;
                return projectFundNumber;
              }),
            },
            {
              Header: 'IRB Protocol Number',
              accessor: 'IRBprotocolNumber',
              Cell: observer(row => {
                const project = row.original;
                const projectIRBprotocolNumber = project.IRBprotocolNumber;
                return projectIRBprotocolNumber;
              }),
            },
            {
              Header: 'Departmental Project ID',
              accessor: 'departmentID',
              Cell: observer(row => {
                const project = row.original;
                const projectDepartmentID = project.departmentID;
                return projectDepartmentID;
              }),
            },
            
            
          ]}
        />:
        <div class="ui placeholder segment">
          <div class="ui icon header color-grey">No Projects available
            </div>
          </div>
        }
        <br />
      </div>
    );
  }

  goto(pathname) {
    const location = this.props.location;
    const link = createLink({ location, pathname });
    this.props.history.push(link);
  }

  handleAddProject = () => {
    this.goto('/projects/add');
  };
  
  crawl = (proj, allValues) => {
    if (!allValues) allValues = [];
    const keysUsed = ['id', 'description', 'complianceCategories', 'proposalID', 'fundNumber', 'IRBprotocolNumber', 'departmentID'];
    const keysObj = ['projectAdmins', 'projectResearchers', 'projectViewers'];
    for (var key in proj) {
      if(keysUsed.includes(key)) {
        allValues.push(proj[key] + " ");
      }
      if(keysObj.includes(key)) {
        if(proj[key].length > 0) {
          for (let i = 0; i < proj[key].length; i++) {
            allValues.push(proj[key][i]);
          }
        }
      }
        //if (typeof proj[key] === "object") this.crawl(proj[key], allValues);
        //else allValues.push(proj[key] + " ");
    }
    return allValues;
  }
  
  handleSearch =(event)=>{
    const searchTerm = event.target.value
    
    const projectList = this.getProjects();
    const userDisplayName = this.getUserDisplayName();
    //console.log('projectList',projectList)
    //var dataArray = Object.keys(projectList).map(val => projectList[val]);
    
    const filteredProjects = projectList.filter((project, index) => {
        const allValues = this.crawl(project);
        //console.log('allvalues', allValues);
        const finalValues = allValues.map(obj => {
      	  if (obj.startsWith("u-")) {
      		  const username = userDisplayName.getDisplayName({ uid: obj.trim() });
      		  //console.log('username',username);
      		  return username + " ";
      	  }
      	  else{
      	    //console.log('object', obj)
      	    return obj;
      	  }
      	});
      	//console.log('finalValues',finalValues);
      	//console.log('finalValues',finalValues.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0);
        return finalValues.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
      });
      //filteredProjects.splice(0, 1);
      //console.log(filteredProjects);
      
      this.setState({
        projectList: []
      },
      () => {
        this.setState({
            projectList: filteredProjects
          });
      }
    );
    
      // this.setState({projectList:[]});
      // this.setState({projectList:filteredProjects});
  }

  renderHeader() {
    const user = this.getUser();
    const isGeneralUser = user?.isGeneralUser;
    const isProjectOwner = user?.isProjectOwner;
    const isAdmin = user?.isAdmin;
    //console.log("Gen", isGeneralUser);
    //console.log("Proj", isProjectOwner);
    //console.log("Admin", isAdmin);
    
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="briefcase" className="align-top" />
          <Header.Content className="left-align">
            Projects
          </Header.Content>
        </Header>
        
        <Input icon='search' className="wsElements" placeholder='Search Projects...' onChange={this.handleSearch.bind(this)}/>
        
        {(isAdmin || isProjectOwner) &&
          <Button color="blue" size="medium" basic onClick={this.handleAddProject}>
            Create Project
          </Button>
        }
      </div>
    );
  }

  renderTotal() {
    return <Label circular>{this.getProjects().length}</Label>;
  }

  render() {
    const store = this.getProjectsStore();
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else {
      content = this.renderMain();
    }
    return (
      <Container className="mt3 animated fadeIn">
        {this.renderHeader()}
        {content}
      </Container>
    );
  }
}

export default inject(
  'awsAccountsStore',
  'projectsStore',
  'userStore',
  'usersStore',
  'userDisplayName',
)(withRouter(observer(ProjectsList)));
